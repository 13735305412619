import './App.css';
import "animate.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="hello animate__animated animate__fadeIn animate__delay-500ms">
          <h1 className="animate__animated animate__fadeIn animate__delay-500ms"> 	⸺ Hello ⸺</h1>
          <div className="animate__animated animate__fadeIn animate__delay-2s ">
            <h3 className="animate__animated animate__slideInUp animate__delay-2s">~welcome to my server~</h3>
          </div>
        </div>
      </header>
      <div className="SecondView">
        Yea, that's it
      </div>
    </div>
  );
}

export default App;
